import React, { Component, createRef } from 'react';
import './App.css';
import LetterGraph from '../applet/LetterGraph';
import Letters from '../presenter/Letters';
import InitialInstructions from '../presenter/InitialInstructions';
import Author from '../presenter/Author';
import ZoomSlider from '../applet/controls/LetterGraphZoomSlider';
import { SHOW_AUTHOR, SHOW_LETTERS, SHOW_INIT_INSTRUCTIONS } from '../store/actionType';
import { INITIAL_INSTRUCTIONS_TITLE, LETTERS_TITLE } from '../applet/appletConfig';
import { SITE_VERSION } from '../siteConfig';

class App extends Component {
  
  constructor(props){
    super(props);

    this.lettersGraph = createRef();
    this.zoomSliderControl = createRef();

    this.state = {
      sidePresenterType: SHOW_INIT_INSTRUCTIONS,
      sidePresenterTitle: INITIAL_INSTRUCTIONS_TITLE,
      sidePresenterData: null,
      sidePresenterToggle: "<"
    }
  }

  handleZoomChange = (event, value) => {
    this.lettersGraph.current.setZoom(value);
  }

  updateZoomSliderValue = (value) => {
    this.zoomSliderControl.current.setZoomValue(value);
  }

  sidePresenter = () => {
    switch(this.state.sidePresenterType)
    {
      case SHOW_INIT_INSTRUCTIONS:
      default:
        return <InitialInstructions />
      case SHOW_LETTERS:
        return <Letters letters={this.state.sidePresenterData} />
      case SHOW_AUTHOR:
        return <Author authorDetails={this.state.sidePresenterData} />
    }
  }

  selectNode = (sidePresenterType, sidePresenterData) => {
    let sidePresenterTitle = '';

    switch(sidePresenterType){
      case SHOW_INIT_INSTRUCTIONS:
      default:
        sidePresenterTitle = INITIAL_INSTRUCTIONS_TITLE;
        break;
      case SHOW_LETTERS:
        sidePresenterTitle = LETTERS_TITLE;
        break;
      case SHOW_AUTHOR:
        sidePresenterTitle = this.lettersGraph.current.stringifyName(sidePresenterData).toLowerCase();
        break;
    }
    
    this.setState({sidePresenterTitle, sidePresenterType, sidePresenterData});
  }

  welcomeMessage = () => {
    this.setState({sidePresenterType: SHOW_INIT_INSTRUCTIONS, sidePresenterTitle: INITIAL_INSTRUCTIONS_TITLE});
    this.lettersGraph.current.deselectAll();
  }

  render(){
    return (
      <div className="app">
        <header className="site-title">
          <h1 onClick={this.welcomeMessage}><span className="site-title-main">Archivepelago</span><span className="version">v{SITE_VERSION}</span></h1>
          <h2>Networked Correspondence Map</h2>
        </header>
        <main>
          <article className="item-article" id="item-info">
            {/* <div id="article-toggle">
              <span>{this.state.sidePresenterToggle}</span>
            </div> */}
            <h3 id="item-info-title">{this.state.sidePresenterTitle}</h3>
            <hr />
            <div id="letters-applet">
              { this.sidePresenter() }
            </div>
          </article>
          <div id="zoom-slider">
            <ZoomSlider id="zoom-slider" ref={this.zoomSliderControl} onSliderChange={this.handleZoomChange}  />
          </div>
          <LetterGraph ref={this.lettersGraph} onZoom={this.updateZoomSliderValue} onSelectNode={this.selectNode} />
        </main>
      </div>
    );
  }
}

export default App;
