import React, { Component } from 'react';
import './Author.css';
import Chip from '@material-ui/core/Chip';

export default class Author extends Component {
    static defaultProps = {
        authorDetails: {}
    }
    
    render(){
        const { authorDetails } = this.props;

        return (
            <React.Fragment>
                <p className="birth-death"><span className="birth">{this.props.authorDetails.BirthDate}</span> - <span className="death">{this.props.authorDetails.DeathDate}</span></p>
                <div className="chips">
                   {
                       authorDetails.Expatriation.split(':').map((el, i)=>{
                        if(el.length > 0)
                        {
                            return <Chip label={el} key={'expat'+i} />
                        }else{
                            return null;
                        }
                       })
                   }
                   {
                       authorDetails.Nationality.split(':').map((el, i)=>{
                        if(el.length > 0)
                        {
                            return <Chip label={el} key={'nation'+i} />
                        }else{
                            return null;
                        }
                       })
                   }
                   {
                       authorDetails.Profession.split(':').map((el, i)=>{
                        if(el.length > 0)
                        {
                            return <Chip label={el} key={'prof'+i} />
                        }else{
                            return null;
                        }
                       })
                   }
                   {
                       authorDetails.Queer.split(':').map((el, i)=>{
                        if(el.length > 0)
                        {
                            return <Chip label={el} key={'queer'+i} />
                        }else{
                            return null;
                        }
                       })
                   }
                   {
                       authorDetails.Religion.split(':').map((el, i)=>{
                        if(el.length > 0)
                        {
                            return <Chip label={el} key={'religion'+i} />
                        }else{
                            return null;
                        }
                       })
                   }
                </div>
            </React.Fragment>
        )
    }
}