import React, { Component } from 'react';
import Slider from '@material-ui/core/Slider';
import { INIT_ZOOM, MIN_ZOOM, MAX_ZOOM, ZOOM_STEP } from '../appletConfig';
import './LetterGraphZoomSlider.css';

export default class LetterGraphZoomSlider extends Component {
    
    constructor(props){
        super(props);
        this.state = {zoomVal: INIT_ZOOM};
    }

    static defaultProps = {
        onSliderChange(){}
    }

    setZoomValue(value){
        if(value !== this.state.zoomVal){
            this.setState({zoomVal: value});
        }
        
    }

    render(){

        return (
            <React.Fragment>
                <div id="zoom-slider-container">
                    <span className="sideways-text">zoom</span>
                    <Slider
                        onChange={this.props.onSliderChange}
                        orientation="vertical"
                        defaultValue={INIT_ZOOM}
                        value={this.state.zoomVal}
                        aria-labelledby="zoom-vertical-slider"
                        step={ZOOM_STEP}
                        min={MIN_ZOOM}
                        max={MAX_ZOOM}
                    />
                </div>
            </React.Fragment>
        )
    }
    
}