import React, { Component } from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";

export default class Letters extends Component {
  
  static defaultProps = {
    letters: []
  }
  
  render(){
    const { To, From } = this.props.letters[0];
    const totalLetterI = this.props.letters.length - 1;

    return (
    <React.Fragment>
      <Timeline>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{ "fontVariant": "small-caps", "fontWeight": "500" }}>
              from<br />{String(To).toUpperCase()}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
          <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography style={{ "fontVariant": "small-caps", "fontWeight": "500" }}>
              from<br />{String(From).toUpperCase()}
            </Typography>
          </TimelineContent>
        </TimelineItem>
        {
          this.props.letters.map((letter, i)=>{
            return (
              <TimelineItem key={i}>
                 <TimelineOppositeContent>
                   <Typography>{ (letter.To === From) && letter.Date }</Typography>
                 </TimelineOppositeContent>
                 <TimelineSeparator>
                   <TimelineDot />
                   {(i < totalLetterI) && <TimelineConnector />}
                 </TimelineSeparator>
                 <TimelineContent>
                   <Typography>{ (letter.To !== From) && letter.Date }</Typography>
                 </TimelineContent>
               </TimelineItem>
            )
          })
        }
      </Timeline>
    </React.Fragment>
  );}
}
